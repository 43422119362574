import React, { SVGProps } from 'react';

const SvgArrowBackIosNew = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M17.77 3.77L16 2 6 12l10 10 1.77-1.77L9.54 12z" />
    </svg>
  );
};

export default SvgArrowBackIosNew;
