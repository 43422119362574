import React, { SVGProps } from 'react';

const SvgBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M14 13.5h4V12h-4v1.5zm0 3h4V15h-4v1.5zM20 7h-5V4c0-1.1-.9-2-2-2h-2c-1.1 0-2 .9-2 2v3H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-9-3h2v5h-2V4zm9 16H4V9h5c0 1.1.9 2 2 2h2c1.1 0 2-.9 2-2h5v11zM9 15c.83 0 1.5-.67 1.5-1.5S9.83 12 9 12s-1.5.67-1.5 1.5S8.17 15 9 15zm2.08 1.18c-.64-.28-1.34-.43-2.08-.43s-1.44.15-2.08.43c-.56.24-.92.78-.92 1.39V18h6v-.43c0-.61-.36-1.15-.92-1.39z" />
      <path
        d="M13 11h-2c-1.1 0-2-.9-2-2H4v11h16V9h-5c0 1.1-.9 2-2 2zm-4 1c.83 0 1.5.67 1.5 1.5S9.83 15 9 15s-1.5-.67-1.5-1.5S8.17 12 9 12zm3 6H6v-.43c0-.6.36-1.15.92-1.39.64-.28 1.34-.43 2.08-.43s1.44.15 2.08.43c.55.24.92.78.92 1.39V18zm6-1.5h-4V15h4v1.5zm0-3h-4V12h4v1.5z"
        opacity={0.3}
      />
    </svg>
  );
};

export default SvgBadge;
